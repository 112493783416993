export const useMarketplace: useMarketplaceReturn = () => {
  const { sendWarning, sendSuccess, sendError } = useNotification()
  const gtm = useGtm()

  const DEFAULT_CUSTOMER_STATE = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    comment: undefined,
    attachment: undefined
  }

  const state = useState<useMarketplaceState>('useMarketplace', () => ({
    company: null,
    selectedBusinessUnit: null,
    selectedService: null,
    selectedSlot: null,
    slots: [],
    bookedAppointment: null,
    encryptedId: null,
    customer: useCloneDeep(DEFAULT_CUSTOMER_STATE),
    error: undefined,
    loading: {
      company: false,
      slots: false,
      booking: false,
      validate: false,
      conversion: false
    }
  }))

  const companySlug = computed(() => useGet(state.value, 'company.slug', ''))
  const website = computed(() => {
    let url = useGet(state.value, 'selectedBusinessUnit.website') || useGet(state.value, 'company.website')
    if (!url) return '#'
    return new URL(url).hostname.replace('www.', '')
  })
  const logo = computed(() => {
    return (
      useGet(state.value, 'selectedBusinessUnit.logo') ||
      useGet(state.value, 'company.logo_url') ||
      '/img/meevo_logo_schrift.png'
    )
  })
  const color = computed(() => {
    return (
      useGet(state.value, 'selectedBusinessUnit.custom_color_primary_hexa') ||
      useGet(state.value, 'company.theme_color_hex') ||
      undefined
    )
  })

  const setCompany = (company: MarketplaceCompany | null) => {
    state.value.company = useCloneDeep(company)
  }
  const setBusinessUnit = (businessUnit: MarketplaceBusinessUnit | null) => {
    state.value.selectedBusinessUnit = useCloneDeep(businessUnit)
  }
  const setService = async (service: MarketplaceService | null) => {
    state.value.selectedService = useCloneDeep(service)
    if (state.value.selectedService) {
      gtm?.trackEvent({
        event: 'appointment-booking-start',
        eventLabel: 'Appointment Booking Started',
        appointmentServiceName: state.value.selectedService.name
      })
      await fetchSlots(state.value.selectedService.uuid)
    }
  }
  const setSlot = (slot: MarketplaceSlot | null) => {
    state.value.selectedSlot = useCloneDeep(slot)
  }
  const clearBookedAppointment = () => {
    state.value.bookedAppointment = null
  }
  const reset = () => {
    state.value.company = null
    state.value.selectedBusinessUnit = null
    state.value.selectedService = null
    state.value.selectedSlot = null
    state.value.slots = []
    state.value.encryptedId = null
    state.value.customer = useCloneDeep(DEFAULT_CUSTOMER_STATE)
    state.value.error = undefined
    state.value.loading = {
      company: false,
      slots: false,
      booking: false,
      validate: false,
      conversion: false
    }
  }

  const validateUuid = (uuid: string, type: 'businessUnit' | 'service'): boolean => {
    if (!uuid) return false
    if (type === 'businessUnit') {
      const businessUnit = state.value.company?.businessUnits?.find((unit) => unit.uuid === uuid)
      if (!businessUnit) return false
      setBusinessUnit(businessUnit)
      return true
    }
    if (type === 'service') {
      let service: MarketplaceService | undefined
      let businessUnit: MarketplaceBusinessUnit | undefined
      state.value.company?.businessUnits?.forEach((unit) => {
        const foundService = unit.services?.find((service) => service.uuid === uuid)
        if (foundService) {
          businessUnit = unit
          service = foundService
        }
      })
      if (!service || !businessUnit) return false
      setBusinessUnit(businessUnit)
      setService(service)
      return true
    }
    return false
  }

  const fetchSlots: MarketplaceFetchSlots = async (serviceUuid: string): Promise<boolean> => {
    if (!serviceUuid) return false
    // if (state.value.loading.slots) return false
    state.value.loading.slots = true
    try {
      state.value.slots = await $fetch<MarketplaceSlot[]>(`/api/marketplace/slots/${serviceUuid}`)
      state.value.error = undefined
      return true
    } catch (error: any) {
      state.value.error = error
      if (error.statusCode === 500) {
        sendError('Leider ist etwas schief gelaufen. Bitte kontaktiere den Kundenservice.')
      }
      if ([403, 401].includes(error.statusCode)) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      return false
    } finally {
      state.value.loading.slots = false
    }
  }

  const book: MarketplaceBookAppointment = async (formData: MarketplaceAppointmentFormData): Promise<boolean> => {
    if (!formData.terms) {
      sendWarning('Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren.')
      return false
    }
    if (!state.value.selectedBusinessUnit) {
      sendWarning('Sie müssen einen Standort auswählen. Bitte versuchen Sie es erneut!')
      return false
    }
    if (!state.value.selectedService || !state.value.selectedSlot) {
      sendWarning('Sie müssen einen Service und ein Datum auswählen. Bitte versuchen Sie es erneut!')
      return false
    }
    state.value.loading.booking = true
    const payload = {
      data: {
        type: 'appointments',
        attributes: {
          service_uuid: state.value.selectedService.uuid,
          external_slot_definition: state.value.selectedSlot.slot_definition,
          customer_email: formData.email,
          customer_name: formData.firstName.trim() + ' ' + formData.lastName.trim(),
          customer_phone_number: formData.phone,
          customer_comment: formData?.comment || undefined,
          attachment_uri: formData?.attachmentUri || undefined
        }
      }
    }
    try {
      const response = await $fetch<MarketplaceAppointment>('/api/marketplace/appointment/book', {
        method: 'POST',
        body: payload
      })
      sendSuccess('Termin wurde erfolgreich gebucht.')
      state.value.bookedAppointment = response
      gtm?.trackEvent({
        event: 'appointment-booked',
        eventLabel: 'Appointment Booked',
        appointmentServiceName: state.value.selectedService.name,
        appointmentBusinessUnit: state.value.selectedBusinessUnit.name
      })
      return true
    } catch (error: any) {
      if (error.statusCode === 500) {
        sendError('Leider ist etwas schief gelaufen. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 403) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 410) {
        sendWarning('Leider ist der Termin nicht mehr verfügbar. Bitte versuchen Sie einem anderen Termin.')
      }
      if (error.statusCode === 422) {
        sendWarning(useGet(error.value, 'data.message', 'Die Anfrage beinhaltet fehlerhafte Daten.'))
      }
      return false
    } finally {
      state.value.loading.booking = false
    }
  }

  // 2024-02-29T08:45:00+00:00/PT60M/2024-02-29T09:45:00+00:00 -> 2024-02-29T08:45:00+00:00
  const getIsoDateFromSlot = (slotDefinition: string = '') => slotDefinition.split('/')[0] || ''
  const getTimeFromSlot = (slotDefinition: string) => $dayjs(getIsoDateFromSlot(slotDefinition)).utc().format('HH:mm')

  return {
    setCompany,
    setService,
    setSlot,
    clearBookedAppointment,
    fetchSlots,
    setBusinessUnit,
    book,
    reset,
    validateUuid,
    companySlug,
    website,
    logo,
    color,
    getIsoDateFromSlot,
    getTimeFromSlot,
    ...toRefs(state.value)
  }
}
