<template>
  <div class="flex h-full flex-col">
    <main class="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 text-center">
      <NuxtImg
        class="mx-auto mb-4 rounded-full"
        height="60px"
        width="60px"
        loading="lazy"
        :src="'/img/meevo_logo_bildmarke.png'"
        alt="Meevo Logo"
      />
      <div class="text-8xl font-bold">{{ error?.statusCode }}</div>
      <h1 class="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">{{ errorTitle }}</h1>
      <p class="mt-4 text-gray-500 dark:text-gray-400">{{ errorText }}</p>
      <NuxtLink :to="{ name: 'meevo-appointment-new-step', params: { step: 1 } }" class="mx-auto">
        <FormBaseButton class="m-4 px-8" :size="ButtonSize.xl">Jetzt Termin buchen</FormBaseButton>
      </NuxtLink>
      <NuxtLink
        :to="'https://www.meevo.de'"
        :rel="'noopener'"
        :target="'_blank'"
        class="mx-auto mt-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:underline"
      >
        zu meevo.de
      </NuxtLink>
    </main>
  </div>
</template>
<script setup lang="ts">
import { ButtonSize } from '~/types/Button'
import type { NuxtError } from '#app'

const props = defineProps({ error: Object as () => NuxtError })

const errorTitle = computed(() =>
  useGet(props, 'error.statusCode') === 404 ? 'Seite nicht gefunden' : 'Fehler aufgetreten'
)
const errorText = computed(() =>
  useGet(props, 'error.statusCode') === 404 ? 'Die gesuchte Seite existiert nicht.' : 'Oops! Es tut uns sehr leid.'
)
</script>
