import type { BusinessUnit, Service, Slot } from '~/composables'

export const useAppointment: useAppointmentReturn = () => {
  const { sendWarning, sendSuccess, sendError } = useNotification()
  const { setStep } = useStep()
  const gtm = useGtm()

  const DEFAULT_CUSTOMER_STATE = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    comment: undefined,
    attachment: undefined
  }

  const state = useState<useAppointmentState>('useAppointment', () => ({
    customer: DEFAULT_CUSTOMER_STATE,
    error: undefined,
    loading: {
      businessUnits: false,
      setupService: false,
      setupBusinessUnit: false,
      services: false,
      slots: false,
      booking: false,
      validate: false,
      conversion: false
    },
    slots: [],
    services: [],
    businessUnits: [],
    selectedService: null,
    selectedBusinessUnit: null,
    selectedSlot: null,
    bookedAppointment: null,
    encryptedId: null
  }))

  const hasBusinessUnit = computed(() => !!state.value.selectedBusinessUnit?.uuid)
  const hasService = computed(() => !!state.value.selectedService?.uuid)
  const hasDate = computed(() => !!state.value.selectedSlot?.uuid)

  const book: BookAppointment = async (formData: AppointmentFormData) => {
    if (!formData.terms) {
      sendWarning('Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren.')
      return false
    }
    if (!state.value.selectedBusinessUnit) {
      sendWarning('Sie müssen einen Standort auswählen. Bitte versuchen Sie es erneut!')
      return false
    }
    if (!state.value.selectedService || !state.value.selectedSlot) {
      sendWarning('Sie müssen einen Service und ein Datum auswählen. Bitte versuchen Sie es erneut!')
      return false
    }
    state.value.loading.booking = true
    const payload = {
      data: {
        type: 'appointments',
        attributes: {
          service_uuid: state.value.selectedService.uuid,
          external_slot_definition: state.value.selectedSlot.slot_definition,
          customer_email: formData.email,
          customer_name: formData.firstName.trim() + ' ' + formData.lastName.trim(),
          customer_phone_number: formData.phone,
          customer_comment: formData.comment || undefined,
          attachment_uri: formData.attachmentUri
        }
      }
    }
    try {
      const response = await $fetch<Appointment>('/api/appointment/book', {
        method: 'POST',
        body: payload
      })
      sendSuccess('Termin wurde erfolgreich gebucht.')
      state.value.bookedAppointment = response
      if (state.value.encryptedId) {
        await trackConversion()
      }
      gtm?.trackEvent({
        event: 'appointment-booked',
        eventLabel: 'Appointment Booked',
        appointmentServiceName: state.value.selectedService.name,
        appointmentBusinessUnit: state.value.selectedBusinessUnit.name
      })
      return true
    } catch (error: any) {
      if (error.statusCode === 500) {
        sendError('Leider ist etwas schief gelaufen. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 403) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 410) {
        sendWarning('Leider ist der Termin nicht mehr verfügbar. Bitte versuchen Sie einem anderen Termin.')
      }
      if (error.statusCode === 422) {
        sendWarning(useGet(error.value, 'data.message', 'Die Anfrage beinhaltet fehlerhafte Daten.'))
      }
      return false
    } finally {
      state.value.loading.booking = false
    }
  }

  const fetchBusinessUnits: FetchBusinessUnits = async () => {
    if (state.value.loading.businessUnits) return false
    state.value.loading.businessUnits = true
    try {
      state.value.businessUnits = await $fetch<BusinessUnit[]>('/api/appointment/businessUnits')
      state.value.error = undefined
      return true
    } catch (error: any) {
      state.value.error = error
      if (error.statusCode === 500) {
        sendError('Leider ist etwas schief gelaufen. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 403) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 401) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      return false
    } finally {
      state.value.loading.businessUnits = false
    }
  }

  const fetchServices: FetchServices = async () => {
    if (state.value.loading.services) return false
    state.value.loading.services = true
    try {
      state.value.services = await $fetch<Service[]>(
        `/api/appointment/services/${state.value.selectedBusinessUnit?.uuid}`
      )
      state.value.error = undefined
      return true
    } catch (error: any) {
      state.value.error = error
      if (error.statusCode === 500) {
        sendError('Leider ist etwas schief gelaufen. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 403) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 401) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      return false
    } finally {
      state.value.loading.services = false
    }
  }

  const fetchService: FetchService = async (uuid: string): Promise<Service | null> => {
    try {
      return await $fetch<Service>(`/api/appointment/service/${uuid}`)
    } catch (error: any) {
      state.value.error = error
      if (error.statusCode === 500) {
        sendError('Leider ist etwas schief gelaufen. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 403) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 401) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      return null
    }
  }

  const fetchSlots: FetchSlots = async () => {
    if (!state.value.selectedService) return false
    if (state.value.loading.slots) return false
    state.value.loading.slots = true
    try {
      state.value.slots = await $fetch<Slot[]>(`/api/appointment/slots/${state.value.selectedService.uuid}`)
      state.value.error = undefined
      return true
    } catch (error: any) {
      state.value.error = error
      if (error.statusCode === 500) {
        sendError('Leider ist etwas schief gelaufen. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 403) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      if (error.statusCode === 401) {
        sendWarning('Die Anfrage ist nicht erlaubt. Bitte kontaktiere den Kundenservice.')
      }
      return false
    } finally {
      state.value.loading.slots = false
    }
  }

  const validateSignedUrl: ValidateSignedUrl = async (signedUrl) => {
    if (state.value.loading.validate) return false
    state.value.loading.validate = true
    try {
      const response = await $fetch('/api/appointment/validate', {
        method: 'POST',
        body: { ...signedUrl }
      })
      if (!(await setupIncomingService(response.service_uuid))) return false
      state.value.customer = usePick(response.customer, ['firstName', 'lastName', 'email', 'phone'])
      state.value.encryptedId = signedUrl.uuid
      return true
    } catch (error: any) {
      return false
    } finally {
      state.value.loading.validate = false
    }
  }

  const setupIncomingService: SetupIncomingService = async (uuid: string) => {
    if (state.value.loading.setupService) return false
    state.value.loading.setupService = true
    try {
      const service = await fetchService(uuid)
      if (!service) return false
      state.value.selectedService = useCloneDeep(service)
      await fetchBusinessUnits()
      const businessUnit = state.value.businessUnits.find((item) => item.uuid === service.businessUnitUuid)
      if (!businessUnit) {
        reset()
        return false
      }
      state.value.selectedBusinessUnit = useCloneDeep(businessUnit)
      await Promise.all([fetchServices(), fetchSlots()])
      gtm?.trackEvent({
        event: 'appointment-booking-start',
        eventLabel: 'Appointment Booking Started',
        appointmentServiceName: state.value.selectedService.name
      })
      setStep(3)
      return true
    } catch (error: any) {
      return false
    } finally {
      state.value.loading.setupService = false
    }
  }

  const setupIncomingBusinessUnit: SetupIncomingBusinessUnit = async (uuid: string) => {
    if (state.value.loading.setupBusinessUnit) return false
    state.value.loading.setupBusinessUnit = true
    try {
      await fetchBusinessUnits()
      const businessUnit = state.value.businessUnits.find((item) => item.uuid === uuid)
      if (!businessUnit) {
        return false
      }
      state.value.selectedBusinessUnit = useCloneDeep(businessUnit)
      await fetchServices()
      setStep(2)
      return true
    } catch (error: any) {
      return false
    } finally {
      state.value.loading.setupBusinessUnit = false
    }
  }

  const trackConversion: TrackConversion = async () => {
    if (!state.value.encryptedId || !state.value.bookedAppointment?.uuid) return false
    if (state.value.loading.conversion) return false
    state.value.loading.conversion = true
    try {
      await $fetch('/api/appointment/finalize', {
        method: 'POST',
        body: {
          encryptedId: state.value.encryptedId,
          appointmentUuid: state.value.bookedAppointment?.uuid
        }
      })
      return true
    } catch (error: any) {
      return false
    } finally {
      state.value.loading.conversion = false
    }
  }

  const setService = (service: Service | null) => {
    state.value.selectedService = useCloneDeep(service)
    if (state.value.selectedService) {
      gtm?.trackEvent({
        event: 'appointment-booking-start',
        eventLabel: 'Appointment Booking Started',
        appointmentServiceName: state.value.selectedService.name
      })
    }
    fetchSlots()
  }

  const setBusinessUnit = (businessUnit: BusinessUnit | null) => {
    state.value.selectedBusinessUnit = useCloneDeep(businessUnit)
    fetchServices()
  }

  const setSlot = (slot: Slot | null) => {
    state.value.selectedSlot = useCloneDeep(slot)
  }

  const clearBookedAppointment = () => {
    state.value.bookedAppointment = null
  }

  // 2024-02-29T08:45:00+00:00/PT60M/2024-02-29T09:45:00+00:00 -> 2024-02-29T08:45:00+00:00
  const getIsoDateFromSlot = (slotDefinition: string = '') => slotDefinition.split('/')[0] || ''
  const getTimeFromSlot = (slotDefinition: string) => $dayjs(getIsoDateFromSlot(slotDefinition)).utc().format('HH:mm')

  const reset = () => {
    state.value.customer = DEFAULT_CUSTOMER_STATE
    state.value.error = undefined
    state.value.loading = {
      businessUnits: false,
      setupService: false,
      setupBusinessUnit: false,
      services: false,
      slots: false,
      booking: false,
      validate: false,
      conversion: false
    }
    state.value.selectedService = null
    state.value.selectedBusinessUnit = null
    state.value.selectedSlot = null
    state.value.services = []
    state.value.businessUnits = []
    state.value.slots = []
    state.value.encryptedId = null
  }

  return {
    reset,
    book,
    fetchBusinessUnits,
    fetchServices,
    fetchService,
    fetchSlots,
    validateSignedUrl,
    setService,
    setBusinessUnit,
    setSlot,
    getIsoDateFromSlot,
    getTimeFromSlot,
    clearBookedAppointment,
    setupIncomingService,
    setupIncomingBusinessUnit,
    hasService,
    hasBusinessUnit,
    hasDate,
    ...toRefs(state.value)
  }
}
