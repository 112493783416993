export default defineNuxtRouteMiddleware(async (to) => {
  // Disable this global middleware on the home page
  if (to.path === '/') return

  const slug = to.params.slug?.toString() || 'meevo'
  const { companySlug, setCompany } = useMarketplace()

  // Disable this global middleware for the Meevo company
  if (companySlug.value === 'meevo') return

  // Check if the companySlug is already validated
  if (companySlug.value === slug) return

  try {
    // Fetch the company from the Marketplace Api using the slug from route params
    const company = await $fetch(`/api/marketplace/company/${slug}`)
    if (!company) throw createError({ statusCode: 404, statusMessage: 'Company not found' })
    useMarketplace().reset()
    setCompany(company)
  } catch (error) {
    throw createError({ statusCode: 404, statusMessage: 'Company not found' })
  }
})
